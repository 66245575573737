$(document).ready( function () {
	
	var $_filterButtons = $('#offer-filters button');
	var $_filterItems = $('.offer-item');
	
	makeLastItemFullWidth();

	$('#offer-filters button').on('click', function () {
		
		var clickedButton = this;

		// For some inexplicble reason this needed a timeout set on it		
		setTimeout(function () {
			$(clickedButton).addClass('on');
			
			if ( $(clickedButton).hasClass('all') ) {
				// All button has been clicked, so show all buttons and return
				$('#offer-filters button').removeClass('on');
				$('#offer-filters button.all').addClass('on');
				$_filterItems.fadeIn();
				setTimeout(makeLastItemFullWidth, 600);
				return;
			} else {
				$('#offer-filters button').removeClass('on');
				$(clickedButton).addClass('on');
			}
			
			var currentCats = '';
			
			// Loop through the "on" buttons and build a class selector string of the active 
			$_filterButtons.each( function () {
				if ( $(this).hasClass('on') ) {
					currentCats += '.' + $(this).data('category') + ', ';
				}
			});
			currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks
			
			// No categories selected so show them all
			if ( currentCats == '' ) {
				$_filterItems.fadeIn();
				return;
			}
			
			// Hide or show the elements that match the class
			$_filterItems.each( function () {
				if ( $(this).is(currentCats) ) {
					$(this).fadeIn();
				} else {
					$(this).fadeOut();
				}
			});

		
			
			setTimeout(makeLastItemFullWidth, 600);
		
		}, 500);
		
	});	
	
	// Pre-filter the offers
	// Get the url parameters
	var urlParams = {};
	var e,
		a = /\+/g,  // Regex for replacing addition symbol with a space
		r = /([^&=]+)=?([^&]*)/g,
		d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
		q = window.location.search.substring(1);

	while (e = r.exec(q))
		urlParams[d(e[1])] = d(e[2]);

	if (urlParams['category']) {
		$('#offer-filters button[data-category="' + urlParams['category'] + '"]').trigger('click');	
	}
	
	
	if ( window.location.hash != '' ) {
		var hashValue = window.location.hash.substring(1); // remove the '#' from the start of the string
		$('button[data-category="' + hashValue + '"]').trigger('click');
	}



	
});


function makeLastItemFullWidth() {
	
	// First set all items to half width
	$('.offer-item').addClass('medium-6');
	
	// If there's an odd number of offer items, make the last one full width
	if ( ($('.offer-item').filter(":visible").length % 2) == 1 ) {
		$('.offer-item').filter(":visible").last().removeClass('medium-6');
	} else {
		$('.offer-item').filter(":visible").last().addClass('medium-6');
	}
	
}