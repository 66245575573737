$(document).ready( function () {
	
	
	// Open/close booking bar buttons
	$('.toggle-booking').on('click', function () {
		$('.booking-bar').css('display', 'block').addClass('open');
		
		if ( window.innerWidth <= 640 ) {
			$('.booking-bar').css('height', window.innerHeight);
		}
	});
	
	$('.booking-bar-close').on('click', function () {
		$('.booking-bar').removeClass('open');
		setTimeout(function () {
			$('.booking-bar').css('display', 'none');
		}, 500);
	});
	
	
	// Implementation of linked check in and out fields with disabled past dates
	var nowTemp = new Date();
	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
	
	var checkin = $('#check-in').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		if (ev.date.valueOf() > checkout.date.valueOf()) {
			var newDate = new Date(ev.date)
			newDate.setDate(newDate.getDate() + 1);
			checkout.update(newDate);
		}
		checkin.hide();
		$('#check-out')[0].focus();
	}).data('datepicker');
	
	var checkout = $('#check-out').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		checkout.hide();
	}).data('datepicker');
	
	
	
	// Widget changing
	$('.booking-bar-selector select').on('change', function () {
		changeBookingBar($(this).val());
	});
	
	
	
	// Show the right widget depending on the current section
	if (window.location.href.indexOf('/wine-dine/') > -1) {
		changeBookingBar('book-table');
	} else if (window.location.href.indexOf('/the-spa/') > -1) {
		changeBookingBar('book-treatment');
	} else if (window.location.href.indexOf('/meetings-events/') > -1) {
		changeBookingBar('book-meeting');
	} else if (window.location.href.indexOf('/weddings-celebrations/celebrations/') > -1) {
		changeBookingBar('book-meeting');
	} else if (window.location.href.indexOf('/weddings-celebrations/weddings/') > -1) {
		changeBookingBar('book-wedding');
	}
	
	

	
	
	// Ensure the widget always fits within the screen, on mobile make it full height
	$(window).on('resize', function () {
		if ( window.innerWidth > 640 ) {
			$('.booking-bar').css('max-height', window.innerHeight);
		} else {
			$('.booking-bar').css('height', window.innerHeight);
		}
	});
	
	
	
	
});




function changeBookingBar(bookingType) {
		
	$('.booking-bar-type').addClass('booking-bar-hidden');
	
	$('.booking-bar-type[data-book="' + bookingType + '"]').removeClass('booking-bar-hidden');
	
	$('.booking-bar-selector select').val(bookingType);
	
}