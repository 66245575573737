/*!
 * ReformSlider
 * Version 0.1
 * Part of the 80 Days Reform Pattern Library
 * http://www.eighty-days.com
 * 
 *
 * TODO:
 * - Implement pagination
 * - Fix the default options
 *
 */


(function ($) {
    $.reformSlider = function (el, options) {
        // To avoid scope issues, use 'base' instead of 'this'
        // to reference this class from internal events and functions.
        var base = this;

        // Access to jQuery and DOM versions of element
        base.$el = $(el);
        base.el = el;

        // Add a reverse reference to the DOM object
        base.$el.data('accordiom', base);

        base.init = function () {
            base.options = $.extend({}, $.reformSlider.defaultOptions, options);

            // Put your initialization code here
        };

        // Sample Function, Uncomment to use
        // base.functionName = function(paramaters){
        // 
        // };

        // Run initializer
        base.init();
    };

    $.reformSlider.defaultOptions = {
        autoplay: true,
        speed: 2000,
        animationSpeed: 500,
        beforeChange: function () {},
        afterChange: function () {},
        onLoad: function () {},
        arrows: false,
        pagination: false,
        paginationArrows: false
    };

    $.fn.reformSlider = function (options) {
        return this.each(function () {
            (new $.reformSlider(this, options));

            // For some reason the default options weren't being extended so I'll do it manually
            if (!options) {
                options = $.reformSlider.defaultOptions;
            } else {
                options = $.extend({}, $.reformSlider.defaultOptions, options);
            }

            // Fire the onLoad callback once all's set up
            if (options.onLoad) {
                options.onLoad.call(this, this);
            }

            var $base = $(this);

            // Set the init classes
            $base.addClass('reform-slider-initialized');
            $base.children().addClass('reform-slide').hide();
            $base.children().first().addClass('reform-slide-current').show();

            var slideCount = $base.children('.reform-slide').length;

            // Add the arrows?
            if ((slideCount > 1) && (options.arrows === true)) {
                $base.after('<ul class="reform-slider-arrows"><li><a href="#" class="reform-prev">Previous</a></li><li><a href="#" class="reform-next">Next</a></li></ul>');
                $('.reform-prev').on('click', previousSlide);
                $('.reform-next').on('click', nextSlide);
            }

            // Add the pagination?
            if ((options.pagination === true) && (slideCount > 1)) {
                var paginationHTMLString = '<ul class="reform-pagination">';

                if (options.paginationArrows === true) {
                    paginationHTMLString += '<li class="reform-pagination-prev"><button>Prev</button></li>';
                }

                for (var i = 0; i < slideCount; i++) {
                    paginationHTMLString += '<li class="reform-pagination-dot"><button>' + (i + 1 * 1) + '</button></li>';
                }

                if (options.paginationArrows === true) {
                    paginationHTMLString += '<li class="reform-pagination-next"><button>Next</button></li>';
                }

                paginationHTMLString += '</ul>';
                $base.after(paginationHTMLString);
                $('.reform-pagination-dot').first().addClass('active');

                $('.reform-pagination button').on('click', function () {
                    paginationClicked(this);
                });
            }

            if (slideCount > 1) {
                if (options.autoplay) {
                    setInterval(nextSlide, options.speed);
                }
            } else {
                console.log('ReformSlider: 1 or fewer slides found');
            }





            // Handle clicks on the pagination buttons 
            function paginationClicked(buttonClicked) {
                var buttonText = $(buttonClicked).text();

                if (buttonText === 'Prev') {
                    previousSlide()
                } else if (buttonText === 'Next') {
                    nextSlide()
                } else {
                    gotoSlide(buttonText);
                }
            }



            // Fade to the next slide
            function nextSlide(e) {
                if (e) {
                    e.preventDefault();
                }

                $base.children('.reform-slide').finish();

                var $currentSlide = $base.children('.reform-slide-current');

                if ($currentSlide.next().length > 0) { // Check if there are more slides after this...
                    $currentSlide.next().show();

                    $currentSlide.fadeOut(options.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.next().addClass('reform-slide-current');
                        updatePagination();
                    });
                } else { // ... if not loop back to the first slide.
                    $currentSlide.siblings().first().show();

                    $currentSlide.fadeOut(options.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.siblings().first().addClass('reform-slide-current');
                        updatePagination();
                    });
                }

                triggerChangeCallbacks();
            }


            // Fade to the previous slide
            function previousSlide(e) {
                if (e) {
                    e.preventDefault();
                }

                $base.children('.reform-slide').finish();

                var $currentSlide = $base.children('.reform-slide-current');

                if ($currentSlide.prev().length > 0) { // Check if there are more slides before this...
                    $currentSlide.prev().show();

                    $currentSlide.fadeOut(options.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.prev().addClass('reform-slide-current');
                        updatePagination();
                    });
                } else { // ... if not loop back to the last slide.
                    $currentSlide.siblings().last().show();

                    $currentSlide.fadeOut(options.animationSpeed, function () {
                        $currentSlide.removeClass('reform-slide-current').hide();
                        $currentSlide.siblings().last().addClass('reform-slide-current');
                        updatePagination();
                    });
                }

                triggerChangeCallbacks();
            }



            // Fade to a specific slide number (counting starts at 1)
            function gotoSlide(slideIndex) {
                $base.children('.reform-slide').finish();

                slideIndex = parseInt(slideIndex, 10) - 1;

                var $currentSlide = $base.children('.reform-slide-current');
                var currentSlideIndex = $base.children('.reform-slide').index($currentSlide);

                // Do nothing if already on that slide
                if (slideIndex === currentSlideIndex) {
                    return;
                }

                $base.children('.reform-slide').eq(slideIndex).show();

                $currentSlide.fadeOut(options.animationSpeed, function () {
                    $currentSlide.removeClass('reform-slide-current').hide();
                    $base.children('.reform-slide').eq(slideIndex).addClass('reform-slide-current');
                    updatePagination();
                });

                triggerChangeCallbacks();
            }



            function triggerChangeCallbacks() {
                if (options.beforeChange) {
                    options.beforeChange.call(this, this);
                }

                if (options.afterChange) {
                    setTimeout(function () {
                        options.afterChange.call(this, this);
                    }, options.animationSpeed + options.animation);
                }
            }



            function updatePagination() {
                var currentSlideIndex = $base.children('.reform-slide').index($('.reform-slide-current'));
                $('.reform-pagination li').removeClass('active');
                $($('.reform-pagination li').get(currentSlideIndex + 1)).addClass('active');
            }


        });
    };


    // Function: show all accordion items
    $.fn.reformSlider.openAll = function (el) {
        $(el).children('.accordionContent').slideDown($(el).data('accordiom-speed'));
    };

    // Function: hide all accordion items
    $.fn.reformSlider.closeAll = function (el) {
        $(el).children('.accordionContent').slideUp($(el).data('accordiom-speed'));
    };

    // Function: open item n (zero indexed)
    $.fn.reformSlider.openItem = function (el, n) {
        var nIndexCount = $(el).children('.accordionButton').length - 1;
        if ((n < 0) || (n > nIndexCount)) { // Quick error check
            throw ('Accordiom: No accordion item of index ' + n + ' exists');
        } else {
            $($(el).children('.accordionButton')[n]).trigger('click');
        }
    };



})(jQuery);